import { TopBar } from "../../meeting/components/TopBar";
import { useEffect, useState } from "react";

export function EmailScreen({
  setReqId,
  setToken,
  setTokenGenerated,
  setSelectedLanguage,
  setPName,
}) {
  const [input, setInput] = useState("");
  const isXLDesktop = useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "45778618",
          formId: "f5254092-83a8-4360-bffc-06d57150f4f7",
          target: "#hbForm",
          onFormSubmit: () => {
            const emailField = document.querySelector('input[name="email"]');
            const emailValue = emailField ? emailField.value : "";
            const selectField = document.querySelector(
              'select[name="select_a_language"]'
            );
            const selectedLanguage = selectField ? selectField.value : "";
            const firstNameField = document.querySelector(
              'input[name="firstname"]'
            );
            const firstNameValue = firstNameField ? firstNameField.value : "";
            handleButtonClick(emailValue, selectedLanguage, firstNameValue);
          },
        });
      }
    });
  }, []);

  const handleButtonClick = async (
    emailValue,
    selectedLanguage,
    firstNameValue
  ) => {
    const url = "https://api.videosdk.live/ai/v1/demo";
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: emailValue,
        referral: "demo",
        name: firstNameValue ?? "",
      }),
    };

    const { id, token } = await fetch(url, options)
      .then((response) => response.json())
      .catch((error) => console.error("error in auth token", error));

    setReqId(id);
    setSelectedLanguage(selectedLanguage);
    setPName(firstNameValue);
    setToken(token);
    setTokenGenerated(true);
    setInput("");
  };

  return (
    <div className="bg-white fixed inset-0 py-4 pr-3 md:pr-4">
      <TopBar bottomBarHeight={60} />

      <div className="mt-2 h-full z-50 flex items-center flex-col justify-center px-10 md:px-40 lg:px-80 xl:px-[500px]">
        <p className="font-mono font-bold text-4xl mb-10">CharacterSDK</p>
        <div id="hbForm" className="characterForm"></div>
      </div>
    </div>
  );
}
