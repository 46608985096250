import { useState, useEffect, useRef } from "react";
import useIsMobile from "../../hooks/useIsMobile";
import useIsTab from "../../hooks/useIsTab";
import videosdkLogo from "../../icons/videosdkLogo.png";

export function TopBar({ bottomBarHeight, characterJoined }) {
  const isMobile = useIsMobile();
  const isTab = useIsTab();

  const [timer, setTimer] = useState(300);
  const handleClick = () => {
    window.open("https://www.videosdk.live/character-sdk", "_blank");
  };

  useEffect(() => {
    let countdown;
    if (characterJoined && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      clearInterval(countdown);
    }

    return () => {
      clearInterval(countdown);
    };
  }, [characterJoined, timer]);

  const displayTime =
    timer <= 10 ? `Demo ends in 00:${timer < 10 ? "0" : ""}${timer}` : "";

  return isMobile || isTab ? (
    <div
      className="flex items-center justify-between "
      style={{ height: bottomBarHeight }}
    >
      <img src={videosdkLogo} style={{ height: bottomBarHeight }} />
      {timer <= 10 ? (
        <p className="font-mono text-sm m-0">
          `Demo ends in 00:${timer < 10 ? "0" : ""}${timer}`
        </p>
      ) : (
        <div
          className="font-mono bg-[#2b2b2b] text-white text-xs shadow-lg rounded py-2 cursor-pointer px-3 "
          onClick={handleClick}
        >
          Join Waitlist
        </div>
      )}
    </div>
  ) : (
    <div
      style={{ height: bottomBarHeight }}
      className="flex items-center justify-between"
    >
      <img src={videosdkLogo} />
      {timer <= 10 ? (
        <p className="font-mono md:text-xl m-0">
          `Demo ends in 00:${timer < 10 ? "0" : ""}${timer}`
        </p>
      ) : (
        <div
          className="font-mono bg-[#2b2b2b] text-white rounded shadow-lg py-2 cursor-pointer px-4 "
          onClick={handleClick}
        >
          Join Waitlist
        </div>
      )}
    </div>
  );
}
