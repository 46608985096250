import React from "react";

function RedditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <g clipPath="url(#clip0_265_38)">
        <path
          fill="#FF4500"
          d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z"
        ></path>
        <path
          fill="#fff"
          d="M40.02 24a3.494 3.494 0 00-3.505-3.504c-.942 0-1.796.383-2.414.972-2.386-1.738-5.684-2.857-9.365-2.975l1.59-7.509 5.213 1.119a2.498 2.498 0 002.503 2.385 2.501 2.501 0 002.503-2.503 2.501 2.501 0 00-4.741-1.119l-5.83-1.237a.683.683 0 00-.472.089.578.578 0 00-.265.383l-1.767 8.363c-3.74.118-7.067 1.207-9.512 2.974a3.508 3.508 0 00-2.414-.972 3.494 3.494 0 00-3.505 3.505c0 1.413.854 2.65 2.062 3.18a6.437 6.437 0 00-.089 1.06c0 5.389 6.273 9.747 14.017 9.747 7.745 0 14.018-4.358 14.018-9.747 0-.353-.03-.707-.089-1.06 1.208-.5 2.062-1.708 2.062-3.151zm-24 2.503A2.5 2.5 0 0118.523 24a2.5 2.5 0 012.503 2.503 2.5 2.5 0 01-2.503 2.503 2.501 2.501 0 01-2.503-2.503zm13.928 6.596c-1.707 1.708-4.976 1.826-5.948 1.826-.972 0-4.24-.118-5.948-1.826-.265-.265-.265-.677 0-.912.265-.266.677-.266.913 0 1.09 1.089 3.386 1.442 5.006 1.442s3.946-.382 5.035-1.472c.265-.265.677-.265.913 0 .295.295.295.707.03.942zm-.441-4.093a2.5 2.5 0 01-2.503-2.503A2.5 2.5 0 0129.507 24a2.5 2.5 0 012.503 2.503 2.501 2.501 0 01-2.503 2.503z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_265_38">
          <path fill="#fff" d="M0 0H48V48H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default RedditIcon;
