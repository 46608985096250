import { useEffect, useState, useRef } from "react";
import { useCharacter, usePubSub } from "@videosdk.live/react-sdk";
import SendIcon from "../../icons/SendIcon";
import Markdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import "katex/dist/katex.min.css";
import useIsMobile from "../../hooks/useIsMobile";
import { XMarkIcon } from "@heroicons/react/24/outline";

function ChatMessage({ message }) {
  return (
    <div className="font-mono mb-5 flex flex-col justify-start items-start text-left">
      <p className="text-[#997272] font-semibold">{message.sender}</p>
      <p className="bg-[#FFFFFF66] p-2 mt-2 inline-block">
        <Markdown
          remarkPlugins={[remarkMath]}
          rehypePlugins={[rehypeKatex]}
          children={message.text}
        ></Markdown>
      </p>
    </div>
  );
}

export function ChatComponent({ height, onClose, recorderRef }) {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const isMobile = useIsMobile();
  const chatRef = useRef(null); 

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  const { sendMessage } = useCharacter(
    {
      id: "general-v1",
      characterMode: "auto_pilot",
    },
    {
      // onCharacterMessage: (d) => {
      //   console.log("character message :: ", d);
      //   setMessages((prevMessages) => [
      //     ...prevMessages,
      //     { sender: "Agent", text: d.text },
      //   ]);
      //   let text =`${d.characterName.length > 10
      //     ? d.characterName.substring(0, 10) + "..."
      //     : d.characterName}: ${d.text}`
      //     if(recorderRef.current){
      //       recorderRef.current.onTranscriptionAvailable(text)
      //     }
      // },
      // onUserMessage: (d) => {
      //   console.log("user message :: ", d);
      //   setMessages((prevMessages) => [
      //     ...prevMessages,
      //     { sender: "User", text: d.text },
      //   ]);
      //    let text =`${d.participantName.length > 10
      //     ? d.participantName.substring(0, 10) + "..."
      //     : d.participantName}: ${d.text}`
      //     if(recorderRef.current){
      //       recorderRef.current.onTranscriptionAvailable(text)
      //     }
      // },
    }
  );

  const handleSendMessage = () => {
    if (input.trim()) {
      setInput("");

      sendMessage(input);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const { messages: characterMessage } = usePubSub("characterMessage", {
    onMessageReceived: ({ payload }) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "Agent", text: payload.text },
      ]);
      if (recorderRef.current) {
        recorderRef.current.onTranscriptionAvailable(`${payload.text}`);
      }
    },
  });

  const { messages: userMessage } = usePubSub("userMessage", {
    onMessageReceived: ({ payload }) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "User", text: payload.text },
      ]);

      if (recorderRef.current) {
        recorderRef.current.onTranscriptionAvailable(`${payload.text}`);
      }
    },
  });

  useEffect(() => {
    const msgs = [];
    for (const message of characterMessage) {
      msgs.push({
        sender: "Agent",
        text: message.payload.text,
        timestamp: message.timestamp,
      });
    }
    for (const message of userMessage) {
      msgs.push({
        sender: "User",
        text: message.payload.text,
        timestamp: message.timestamp,
      });
    }
    msgs.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    setMessages(msgs);
  }, [characterMessage, userMessage]);

  return (
    <div className="border-2 w-full  border-[#86886C33] flex flex-col py-4 px-4 bg-gradient-to-b from-[#F7D0C180] to-[#E7DFDC80]">
      {isMobile && (
        <div onClick={onClose} className="flex justify-end">
          <XMarkIcon className="h-7 w-7 " />
        </div>
      )}

      <div
        style={{
          maxHeight: isMobile ? 300 : height,
          height: isMobile ? 300 : height,
        }}
        className="  w-full overflow-y-auto scrollbar scrollbar-primary "
        ref={chatRef}
      >
        {messages.map((message, index) => (
          <ChatMessage message={message} key={index} />
        ))}
      </div>

      <div className="border border-[#99727233] p-3 flex flex-row justify-between">
        <input
          name="myInput"
          type="text"
          placeholder="Write your message"
          className=" placeholder:text-[#997272] bg-[#F4EEEC] w-full mr-3"
          onChange={(e) => setInput(e.target.value)}
          value={input}
          onKeyDown={handleKeyDown}
        />
        <div onClick={handleSendMessage} className="cursor-pointer">
          <SendIcon />
        </div>
      </div>
    </div>
  );
}
