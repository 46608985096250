import { usePubSub } from "@videosdk.live/react-sdk";
import { useEffect, useState } from "react";
import useIsMobile from "../../hooks/useIsMobile";
import { XMarkIcon } from "@heroicons/react/24/outline";
import useIsSMMobile from "../../hooks/useIsSMMobile";
import useIsMMobile from "../../hooks/useIsMMobile";
import useIsTab from "../../hooks/useIsTab";
import useIsLGDesktop from "../../hooks/useIsLGDesktop";
import useIsBigDesktop from "../../hooks/useIsBigScreen";
import { LineChart } from "@tremor/react";

export function LatencyView({ height, onClose }) {
  const isMobile = useIsMobile(425);
  const isSmallMobile = useIsSMMobile();
  const isMediumMobile = useIsMMobile();
  const isTab = useIsTab();
  const isLG = useIsLGDesktop();
  const isBigScreen = useIsBigDesktop();

  const [chartData, setChartData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0); // Start from 4 as initial data has 3 entries

  const valueFormatter = (number) => {
    return new Intl.NumberFormat("us").format(number).toString() + " ms";
  };

  // Handle incoming latency messages
  usePubSub("INTELLIGENCE_LATENCY", {
    onMessageReceived: ({ message, payload }) => {
      const newTtfb = parseFloat(payload.ttfb.toFixed(3)) * 1000; // Convert to ms
      setChartData((prevData) => [
        ...prevData,
        { index: currentIndex, Latency: newTtfb },
      ]);
      setCurrentIndex((prevIndex) => prevIndex + 1); // Increment index
    },
  });

  // Calculate highest, lowest, and average latency
  const highestLatency = Math.max(...chartData.map((item) => item.Latency));
  const lowestLatency = Math.min(...chartData.map((item) => item.Latency));
  const averageLatency =
    chartData.length > 0
      ? (
          chartData.reduce((acc, val) => acc + val.Latency, 0) /
          chartData.length
        ).toFixed(2)
      : 0;

  const customTooltip = (props) => {
    const { payload, active } = props;
    if (!active || !payload) return null;
    return (
      <div className="w-32 rounded-tremor-default border border-tremor-border bg-tremor-background p-2 text-tremor-default shadow-tremor-dropdown">
        {payload.map((category, idx) => (
          <div key={idx} className="flex flex-1 space-x-2.5">
            <div className={`flex w-1 flex-col bg-[#998972] rounded`} />
            <div className="space-y-1">
              <p className="text-tremor-content">{category.dataKey}</p>
              <p className="font-medium text-tremor-content-emphasis">
                {category.value} ms
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="bg-gradient-to-b from-[#F8D9AC1A] to-[#FFFAEF1A] border-2 border-[#86886C33] h-full font-mono py-4 px-5">
      <div className={isMobile && "flex justify-between items-center"}>
        <p className="text-[#2B2B2B] text-xl">Latency</p>
        <div onClick={onClose}>
          {isMobile && <XMarkIcon className="h-7 w-7" />}
        </div>
      </div>

      <div
        style={{ height: isMobile ? 230 : height }}
        className="mt-6 overflow-hidden"
      >
        {chartData.length > 0 ? (
          <LineChart
            data={chartData}
            categories={["Latency"]}
            colors={["#998972"]}
            showLegend={false}
            showXAxis={false}
            showYAxis={false}
            showGridLines={false}
            startEndOnly={true}
            className="h-full w-full"
            customTooltip={customTooltip}
            valueFormatter={(value) => `${value} ms`} // Only show latency, not index
          />
        ) : (
          <div className="animate-pulse w-full h-full px-4 bg-[#F9DEB4]"></div>
        )}
      </div>

      <hr className="border-[1px] mt-4 border-[#86886C]" />
      <div className="flex flex-row font-mono text-[#86886C] font-semibold justify-between mt-5">
        <p>H: {valueFormatter(highestLatency)}</p>
        <p>M: {valueFormatter(averageLatency)}</p>
        <p>L: {valueFormatter(lowestLatency)}</p>
      </div>
    </div>
  );
}
