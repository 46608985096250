import React from "react";

function LatencyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#86886C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 12h-4l-3 9L9 3l-3 9H2"
      ></path>
    </svg>
  );
}

export default LatencyIcon;
