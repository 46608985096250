import { TopBar } from "../../meeting/components/TopBar";
import LinkedinIcon from "../../icons/LinkedinIcon";
import RedditIcon from "../../icons/RedditIcon";
import WhatsappIcon from "../../icons/WhatsappIcon";
import XIcon from "../../icons/XIcon";
import FacebookIcon from "../../icons/FacebookIcon";
import { useState } from "react";
import { ArrowDownTrayIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { FFmpeg } from "@ffmpeg/ffmpeg";

export function LeaveScreen({ downloadUrl, recordingBlob }) {
  const ffmpeg = new FFmpeg({ log: true });
  const [isConverted, setIsConverted] = useState(true);
  const arr = [
    {
      Icon: XIcon,
    },
    {
      Icon: FacebookIcon,
    },
    {
      Icon: RedditIcon,
    },
    {
      Icon: WhatsappIcon,
    },
    {
      Icon: LinkedinIcon,
    },
  ];
  // const handleClick = (link) => {
  //   window.open(link, "_blank");
  // };

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = "character.webm";
    a.click();
  };

  const handleConvert = async () => {
    // Load FFmpeg and convert the file
    setIsConverted(false);
    const arrayBuffer = await recordingBlob.arrayBuffer();
    await ffmpeg.load();
    ffmpeg.writeFile("character.webm", new Uint8Array(arrayBuffer));
    await ffmpeg.exec(["-i", "character.webm", "output.mp4"]);
    const data = await ffmpeg.readFile("output.mp4");
    const mp4Blob = new Blob([data.buffer], { type: "video/mp4" });

    const mp4Url = URL.createObjectURL(mp4Blob);
    const a = document.createElement("a");
    a.href = mp4Url;
    a.download = "character.mp4";
    a.click();
    setIsConverted(true);
  };

  return (
    <div className="bg-white fixed inset-0 pr-3 md:pr-4">
      <TopBar bottomBarHeight={60} />
      <div className="h-full flex flex-col justify-center items-center">
        <p
          className="text-3xl md:text-5xl mt-8 text-center font-bold bg-gradient-to-b from-[#FF726A] to-[#FDA84F] bg-clip-text"
          style={{
            backgroundClip: "text",
            color: "transparent",
            WebkitBackgroundClip: "text",
          }}
        >
          Spread the word
        </p>

        <p className="font-mono text-[#515151] mt-4 text-center md:text-xl 2xl:text-3xl ">
          Click below to download your recording instantly!{" "}
        </p>
        <div className="flex flex-col md:flex-row">
          <div
            className={`${
              downloadUrl ? "" : "animate-pulse  cursor-progress"
            } shadow-xl mt-9 font-semibold rounded-lg flex flex-row items-center justify-center cursor-pointer bg-[#2B2B2B] text-white py-3 px-6`}
            onClick={downloadUrl && handleDownload}
          >
            <ArrowDownTrayIcon className="h-5 w-5 mr-2" />
            Download the recording
          </div>
          <div
            className={`
              shadow-xl mt-9 md:ml-3 font-semibold rounded-lg flex flex-row items-center justify-center cursor-pointer bg-[#2B2B2B] text-white py-3 px-6`}
            onClick={handleConvert}
          >
            <ArrowPathIcon className={`h-5 w-5 mr-2 ${isConverted ? "" : "animate-spin"}`} />
             {isConverted ? "Convert to mp4" : "Converting..."}
          </div>
        </div>

        <p className="  mt-32 text-center text-2xl  md:text-4xl  font-bold text-[#2B2B2B]">
          Share it across social media
        </p>
        <div className="mt-5 flex flex-row items-center justify-center ">
          {arr.map(({ Icon }, index) => (
            <div
              className={` mr-3 md:mr-10 last:mr-0  flex flex-row  text-white items-center justify-center `}
              key={index}
              // onClick={() => handleClick(link)}
            >
              <Icon />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
