import { createContext, useContext, useEffect, useState } from "react";
import { VirtualBackgroundProcessor } from "@videosdk.live/videosdk-media-processor-web";
import { participantModes } from "../utils/common";
import useIsMobile from "../hooks/useIsMobile";

export const MeetingAppContext = createContext();

export const useMeetingAppContext = () => useContext(MeetingAppContext);

export const MeetingAppProvider = ({
  children,
  initialMicOn,
  initialWebcamOn,
  participantMode,
  initialSpeakerOn,
}) => {
  const isMobile = useIsMobile();

  const [sideBarMode, setSideBarMode] = useState(null);
  const [selectedMicrophone, setSelectedMicroPhone] = useState({
    id: null,
    label: null,
  });
  const [selectedWebcam, setSelectedWebcam] = useState({
    id: null,
    label: null,
  });
  const [selectedSpeaker, setSelectedSpeaker] = useState({
    id: null,
    label: null,
  });
  const [isCameraPermissionAllowed, setIsCameraPermissionAllowed] =
    useState(null);
  const [isMicrophonePermissionAllowed, setIsMicrophonePermissionAllowed] =
    useState(null);

  const [selectedWebcamDevice, setSelectedWebcamDevice] =
    useState(selectedWebcam);
  const [selectedMicDevice, setSelectedMicDevice] =
    useState(selectedMicrophone);
  const [raisedHandsParticipants, setRaisedHandsParticipants] = useState([]);
  const [useVirtualBackground, setUseVirtualBackground] = useState(
    participantMode !== participantModes.CLIENT && !isMobile
  );
  const [webCamResolution, setWebCamResolution] = useState("h480p_w640p");
  const [participantLeftReason, setParticipantLeftReason] = useState(null);
  const [cameraFacingMode, setCameraFacingMode] = useState({
    facingMode: "front",
  });
  const [meetingMode, setMeetingMode] = useState(null);

  const [muteSpeaker, setMuteSpeaker] = useState(initialSpeakerOn);
  const [selectedOutputDevice, setSelectedOutputDevice] =
    useState(selectedMicrophone);
  const [livelinessChecked, setLivelinessChecked]=useState(null)
  const [faceImageSrc, setFaceImageSrc]=useState(null)
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [isProcessing, setIsProcessing]=useState(false)
  const [isKycCompleted, setIsKycCompleted]= useState(false);
  const [transcription, setTranscription] = useState();
  const [isLottieRender, setIsLottieRender] = useState(false);
  const [isTextRender, setIsTextRender] = useState(false);
  const [isLivelinessChecked, setIsLivelinessChecked] = useState(false);
  const [isIDVerficationChecked, setIsIDVerficationChecked] = useState(null)
  const [kycData, setKycData]=useState(null)

  const [diagramData,setDiagramData] = useState(null)
  const [activeState,setActiveState] = useState(null)


  const videoProcessor = new VirtualBackgroundProcessor();

  const isAgent =
    !!participantMode && participantMode !== participantModes.CLIENT;

  useEffect(() => {
    setMuteSpeaker(initialSpeakerOn);
  }, [initialSpeakerOn]);

  return (
    <MeetingAppContext.Provider
      value={{
        // default options
        initialMicOn,
        initialWebcamOn,
        participantMode,

        allowedVirtualBackground: false, //isAgent && !isMobile,

        maintainVideoAspectRatio: isAgent,
        maintainLandscapeVideoAspectRatio: true,
        canRemoveOtherParticipant: isAgent,

        // states
        sideBarMode,
        selectedWebcamDevice,
        selectedMicDevice,
        raisedHandsParticipants,
        useVirtualBackground,
        participantLeftReason,
        meetingMode,
        muteSpeaker,
        selectedOutputDevice,
        webCamResolution,
        cameraFacingMode,
        selectedSpeaker,
        isCameraPermissionAllowed,
        isMicrophonePermissionAllowed,
        selectedWebcam,
        selectedMicrophone,
        livelinessChecked,
        faceImageSrc,
        showImagePreview,
        isProcessing,
        isKycCompleted,
        transcription,
        isLottieRender,
        isTextRender,
        isLivelinessChecked,
        isIDVerficationChecked,
        kycData,
        diagramData,
        activeState,

        // setters
        setSideBarMode,
        setSelectedMicDevice,
        setSelectedWebcamDevice,
        setRaisedHandsParticipants,
        setUseVirtualBackground,
        setParticipantLeftReason,
        setMeetingMode,
        setMuteSpeaker,
        setSelectedOutputDevice,
        setWebCamResolution,
        setCameraFacingMode,
        setSelectedWebcam,
        setSelectedSpeaker,
        setIsCameraPermissionAllowed,
        setIsMicrophonePermissionAllowed,
        setSelectedMicroPhone,
        setLivelinessChecked,
        setFaceImageSrc,
        setShowImagePreview,
        setIsProcessing,
        setIsKycCompleted,
        setTranscription,
        setIsLottieRender,
        setIsTextRender,
        setIsLivelinessChecked,
        setIsIDVerficationChecked,
        setKycData,
        setActiveState,
        setDiagramData,

        videoProcessor,
      }}
    >
      {children}
    </MeetingAppContext.Provider>
  );
};
