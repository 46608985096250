import React from "react";

function SendIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#clip0_31_6)">
        <path
          fill="#997272"
          d="M.01 19L20 10.5.01 2 0 8.611 14.286 10.5 0 12.389.01 19z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_31_6">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SendIcon;
