import React from "react";

function SpeakingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="27"
      fill="none"
      viewBox="0 0 26 27"
    >
      <path fill="#2B2B2B" d="M0 26.82h26v-26H0v26z"></path>
    </svg>
  );
}

export default SpeakingIcon;
