import { useEffect, useState } from "react";
import EyeFocusIcon from "../../icons/EyeFocusIcon";
import { TopBar } from "../../meeting/components/TopBar";
import useIsMobile from "../../hooks/useIsMobile";
import SpeakingIcon from "../../icons/SpeakingIcon";

const arr = [
  {
    Icon: "🗣",
    heading: "Real-time Interaction",
    description:
      "Engage through speaking, listening, and visual recognition in real-time",
  },
  {
    Icon: "💭",
    heading: "Think & Reason",
    description:
      "Understand conversations, adapt based on context, and respond intelligently",
  },
  {
    Icon: "📝",
    heading: "Actions on Command",
    description:
      "Connect with third-party tools and take action to get things done",
  },
];

export function QueueScreen({
  token,
  reqId,
  setMeetingId,
  setMeetingIdGenerated,
  setToken,
}) {
  const isMobile = useIsMobile();
  const [queueNumber, setQueueNumber] = useState(null);

  useEffect(() => {
    const fetchQueueStatus = async () => {
      try {
        const url = `https://api.videosdk.live/ai/v1/demo/${reqId}`;
        const options = {
          method: "POST",
          headers: { Authorization: token, "Content-Type": "application/json" },
        };

        const response = await fetch(url, options);
        const data = await response.json();

        // if (data.queue === 0 && data.meeting) {
        if (data.queue === 0 && data.meeting) {
          setMeetingId(data.meeting.id);
          setToken(data.meeting.token);
          setMeetingIdGenerated(true);
          clearInterval(intervalId);
        } else {
          setQueueNumber(data.queue);
        }
      } catch (error) {
        console.error("Error fetching queue status:", error);
      }
    };

    fetchQueueStatus();
    // Set an interval to call the function every 5 seconds
    const intervalId = setInterval(() => {
      fetchQueueStatus();
    }, 5000);

    return () => clearInterval(intervalId);

    // Ensure dependencies are correctly added
  }, [token, reqId, setMeetingId, setMeetingIdGenerated]);

  return (
    <div
      className={`bg-white fixed inset-0 py-4 pr-4 ${
        isMobile ? "overflow-y-auto" : ""
      } `}
    >
      <TopBar bottomBarHeight={60} />
      <div className="h-full flex flex-col justify-evenly ">
        <div className="py-3 px-12 md:px-24 flex flex-col items-center text-center">
          <p className="text-[#2B2B2B] text-3xl md:text-5xl font-bold">
            You're now in a queue
          </p>
          <p className="font-mono text-[#848484] mt-5 mb-4">
            Hang tight! There are {queueNumber || 0} people ahead of you. You'll
            be joining the demo soon!
          </p>
        </div>

        <div
          className={`flex ${
            isMobile ? "flex-col items-center" : "flex-row"
          } px-5 lg:px-8 xl:px-20 mt-2 md:mt-0`}
        >
          {arr.map(({ Icon, heading, description }) => (
            <div
              className={`mb-5 md:mb-0 py-5 text-center flex flex-col items-center bg-gradient-to-b from-[#FDCBC880] to-[#F7D0A780] md:mr-5 lg:mr-8 xl:mr-12 md:px-3 xl:px-14 last:mr-0`}
              key={heading}
            >
              <div className="bg-[#FEFDF9] p-2 flex items-center justify-center rounded-full text-3xl">
                {/* <Icon /> */}
                <p className="m-0 text-center">{Icon}</p>
              </div>
              <p className="text-[#2B2B2B] text-xl md:text-lg lg:text-xl font-bold mb-3 mt-5 ">
                {heading}
              </p>
              <p className="text-[#848484] font-mono font-normal text-xs ">
                {description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
