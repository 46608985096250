import React, { useEffect, useState } from "react";
import { MeetingProvider } from "@videosdk.live/react-sdk";
import { LeaveScreen } from "./components/screens/LeaveScreen";
import { MeetingContainer } from "./meeting/MeetingContainer";
import { MeetingAppProvider } from "./context/MeetingAppContext";
import useIsMobile from "./hooks/useIsMobile";
import { EmailScreen } from "./components/screens/EmailScreen";
import { QueueScreen } from "./components/screens/QueueScreen";

const App = () => {
  const [token, setToken] = useState("");
  const [meetingId, setMeetingId] = useState("");
  const [downloadUrl, setDownloadUrl] = useState(null);

  const [isMeetingIdGenerated, setMeetingIdGenerated] = useState(false);
  const [isTokenGenerated, setTokenGenerated] = useState(false);
  const [isMeetingLeft, setIsMeetingLeft] = useState(false);
  const [reqId, setReqId] = useState("");
  const [recordingBlob, setRecordingBlob] = useState(null);

  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [pName, setPName] = useState(null);

  let isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile) {
      window.onbeforeunload = () => {
        return "Are you sure you want to exit?";
      };
    }
  }, [isMobile]);

  return (
    <>
      <MeetingAppProvider
        initialMicOn={true}
        initialWebcamOn={true}
        initialSpeakerOn={true}
      >
        {isTokenGenerated ? (
          isMeetingIdGenerated ? (
            <MeetingProvider
              config={{
                meetingId: meetingId,
                micEnabled: true,
                webcamEnabled: true,
                name: pName,
                multiStream: false,
              }}
              token={token}
              reinitialiseMeetingOnConfigChange={true}
              joinWithoutUserInteraction={true}
            >
              <MeetingContainer
                setDownloadUrl={setDownloadUrl}
                setRecordingBlob={setRecordingBlob}
                selectedLanguage={selectedLanguage}
                reqId={reqId}
                onMeetingLeave={() => {
                  setToken("");
                  setMeetingId("");
                  setMeetingIdGenerated(false);
                  setIsMeetingLeft(true);
                }}
              />
            </MeetingProvider>
          ) : isMeetingLeft ? (
            <LeaveScreen
              setIsMeetingLeft={setIsMeetingLeft}
              downloadUrl={downloadUrl}
              recordingBlob={recordingBlob}
            />
          ) : (
            <QueueScreen
              token={token}
              setToken={setToken}
              reqId={reqId}
              setMeetingId={setMeetingId}
              setMeetingIdGenerated={setMeetingIdGenerated}
            />
          )
        ) : (
          <EmailScreen
            setToken={setToken}
            setReqId={setReqId}
            setTokenGenerated={setTokenGenerated}
            setSelectedLanguage={setSelectedLanguage}
            setPName={setPName}
          />
        )}
      </MeetingAppProvider>
    </>
  );
};

export default App;
