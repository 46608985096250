import React, { useState, useEffect, useRef, useMemo } from "react";
import mermaid from "mermaid";
import { usePubSub, useCharacter } from "@videosdk.live/react-sdk";
import useIsMobile from "../../hooks/useIsMobile";
import { XMarkIcon } from "@heroicons/react/24/solid";

function convertToStateDiagram(data) {
  const { states, tree } = data;
  const activeState = tree.active;
  let stateDiagram = "stateDiagram\n";

  // Step 1: Add the state definitions
  for (let key in states) {
    const state = states[key];
    if (key !== "Default") {
      stateDiagram += `    state "${state.title}" as ${state.id}\n`;
    }
  }

  // Step 2: Define transitions
  for (let key in tree) {
    if (key !== "active" && key !== "root") {
      const transitions = tree[key];
      for (let transition in transitions) {
        stateDiagram += `    ${key} --> ${transitions[transition]}: ${transition}\n`;
      }
    }
  }

  // Step 3: Add class definitions
  stateDiagram += `
    classDef active fill:#DFE5C6,stroke:#DFE5C6,color:#2B2B2B
    classDef inactive fill:#F8F5E2,stroke:#86886C33,color:#2B2B2B\n`;

  // Step 4: Determine which states are active or inactive

  for (let key in states) {
    if (key === activeState) {
      stateDiagram += `    class ${key} active\n`;
    } else if (key !== "Default") {
      stateDiagram += `    class ${key} inactive\n`;
    }
  }

  return stateDiagram;
}

const FlowChartView = ({ containerHeight, onClose }) => {
  const [uniqueId, setUniqueId] = useState();
  let [diagram, setDiagram] = useState(null);
  const [svg, setSvg] = useState("");

  const activeStateRef = useRef();
  const diagramRef = useRef();

  useCharacter(
    {
      id: "general-v1",
      characterMode: "auto_pilot",
    }
    // {
    //   onData: (topic, data) => {
    //     console.log("hello from here", topic, data);
    //     if (topic === "WORKFLOW") {
    //       const tempId = `graphDiv-${Math.random().toString(36).substr(2, 9)}`;
    //       setUniqueId(tempId);
    //       const diagramToSet = convertToStateDiagram(data);
    //       setDiagram(diagramToSet);
    //       activeStateRef.current = data.tree.active;
    //       console.log("diagram", diagram, activeStateRef.current);
    //     } else if (topic === "ON_WORKFLOW_STATE_CHANGED") {
    //       // const newDiagram = diagram.replace(`class ${activeStateRef.current} active `)
    //       const newDiagram = diagramRef.current;
    //       let changeDiagram = newDiagram.replace(
    //         `class ${activeStateRef.current} active`,
    //         `class ${activeStateRef.current} inactive`
    //       );
    //       changeDiagram = changeDiagram.replace(
    //         `class ${data.active_state} inactive`,
    //         `class ${data.active_state} active`
    //       );
    //       console.log("changeDiagram", changeDiagram);
    //       const tempId = `graphDiv-${Math.random().toString(36).substr(2, 9)}`;
    //       setUniqueId(tempId);
    //       setDiagram(changeDiagram);
    //       activeStateRef.current = data.active_state;
    //     }
    //   },
    // }
  );

  // useEffect(() => {
  //   diagramRef.current = diagram;
  // }, [diagram]);

  const { messages: workflowMessages } = usePubSub("WORKFLOW", {
    onMessageReceived: ({ payload }) => {
      const tempId = `graphDiv-${Math.random().toString(36).substr(2, 9)}`;
      setUniqueId(tempId);
      const diagramToSet = convertToStateDiagram(payload);
      setDiagram(diagramToSet);
      activeStateRef.current = payload.tree.active;
    },
  });

  const { messages: workflowActiveMessages } = usePubSub(
    "ON_WORKFLOW_STATE_CHANGED",
    {
      onMessageReceived: ({ payload }) => {
        const newDiagram = diagramRef.current;
        let changeDiagram = newDiagram.replace(
          `class ${activeStateRef.current} active`,
          `class ${activeStateRef.current} inactive`
        );
        changeDiagram = changeDiagram.replace(
          `class ${payload.active_state} inactive`,
          `class ${payload.active_state} active`
        );
        const tempId = `graphDiv-${Math.random().toString(36).substr(2, 9)}`;
        setUniqueId(tempId);
        setDiagram(changeDiagram);
        activeStateRef.current = payload.active_state;
      },
    }
  );

  // useEffect(() => {
  //   const payload = workflowMessages.at(-1)?.payload;
  //   if (!payload) {
  //     return;
  //   }
  //   const newDiagram = diagramRef.current;
  //   let changeDiagram = newDiagram.replace(
  //     `class ${activeStateRef.current} active`,
  //     `class ${activeStateRef.current} inactive`
  //   );
  //   changeDiagram = changeDiagram.replace(
  //     `class ${payload.active_state} inactive`,
  //     `class ${payload.active_state} active`
  //   );
  //   console.log("changeDiagram", changeDiagram);
  //   const tempId = `graphDiv-${Math.random().toString(36).substr(2, 9)}`;
  //   setUniqueId(tempId);
  //   // setDiagram(changeDiagram);
  //   activeStateRef.current = payload.active_state;
  // }, [workflowActiveMessages]);

  useEffect(() => {
    const payload = workflowMessages.at(-1)?.payload;
    if (payload) {
      const tempId = `graphDiv-${Math.random().toString(36).substr(2, 9)}`;
      setUniqueId(tempId);
      const diagramToSet = convertToStateDiagram(payload);
      setDiagram(diagramToSet);
      activeStateRef.current = payload.tree.active;
      diagramRef.current = diagramToSet;
      // return diagramToSet;
    }
  }, [, workflowMessages]);

  useEffect(() => {
    if (diagram) {
      mermaid.initialize({ startOnLoad: true });

      const renderDiagram = async () => {
        try {
          const { svg } = await mermaid.render(uniqueId, diagram);
          setSvg(svg);
        } catch (err) {
          console.error("Error rendering Mermaid diagram:", err);
        }
      };

      renderDiagram();
    }
  }, [uniqueId, diagram]);

  const isMobile = useIsMobile();

  if (!svg)
    return (
      <div className="text-3xl mermaid flex items-center justify-center border-2 border-[#86886C33] text-ellipsis bg-gradient-to-b from-[#EEEBC380] to-[#FFF4E580] font-mono p-5 h-full">
        <div className="animate-pulse w-full h-full px-4 bg-[#DFE5C6]"></div>
      </div>
    );

  return (
    <>
      {isMobile && (
        <div onClick={onClose} className="flex justify-end mb-2">
          <XMarkIcon className="h-7 w-7 " />
        </div>
      )}
      <div
        className="mermaid flex items-center justify-center border-2 border-[#86886C33] text-ellipsis bg-gradient-to-b from-[#EEEBC380] to-[#FFF4E580] font-mono p-5 h-full"
        dangerouslySetInnerHTML={{ __html: svg }}
        style={{ height: isMobile && containerHeight - 120 }}
      />
    </>
  );
};

export default FlowChartView;

