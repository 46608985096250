import React from "react";

function SwitchCameraIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#2B3034"
        d="M20 20.5H4a2 2 0 01-2-2v-11a2 2 0 012-2h16a2 2 0 012 2v11a2 2 0 01-2 2z"
      ></path>
      <path
        fill="#2B3034"
        d="M17.5 7h-11l1.424-2.492A2 2 0 019.661 3.5h4.678a2 2 0 011.737 1.008L17.5 7z"
      ></path>
      <path
        fill="#fff"
        d="M18.5 13.5h-4l2-2.666 2 2.666zM5.5 12.5h4l-2 2.666-2-2.666z"
      ></path>
      <path
        fill="#fff"
        d="M15.299 9.268A4.945 4.945 0 0012 8a5.006 5.006 0 00-4.977 5.459h1.005A4.005 4.005 0 0112 9a3.96 3.96 0 012.699 1.068l.6-.8zm1.675 3.232h-1.009c.021.164.035.33.035.5 0 2.206-1.794 4-4 4a3.96 3.96 0 01-2.699-1.068l-.6.799A4.942 4.942 0 0012 18a5.006 5.006 0 004.974-5.5z"
      ></path>
    </svg>
  );
}

export default SwitchCameraIcon;
