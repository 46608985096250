import { useRef, forwardRef, useEffect, useImperativeHandle } from "react";
import imageData from "../../static/logo192.png";
import { FFmpeg } from "@ffmpeg/ffmpeg";
const Worker = forwardRef(
  (
    {
      ParticipantAudioStream,
      CharacterAudioStream,
      ParticipantVideoStream,
      CharacterVideoStream,
      setDownloadUrl,
      setRecordingBlob,
      ParticipantName = "Participant",
      CharacterName = "Character AI",
    },
    ref
  ) => {
    const canvasRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const chunksRef = useRef([]);
    const presignedUrlRef = useRef(null)
    const recordingRef = useRef(false);
    const logoRef = useRef(null);
    const videoStreamsRef = useRef([]);
    const analyserRef = useRef(null);
    const messageRef = useRef(null);
    const fontFamily = "Bricolage Grotesque";
    const fileType = "webm";
    const ffmpeg = new FFmpeg({ log: true });

    useEffect(() => {
      async function setupAudioAndVideo() {
        try {
          const audioContext = new AudioContext();
          const analyser = audioContext.createAnalyser();
          analyser.fftSize = 1024;
          analyserRef.current = analyser;

          const source = audioContext.createMediaStreamSource(
            ParticipantAudioStream
          );
          // const characterMediaStream = new MediaStream()
          // characterMediaStream.addTrack(Characterx/AudioStream.track)
          // console.log(typeof characterMediaStream, characterMediaStream.getAudioTracks())
          // characterMediaStream.getAudioTracks()
          const source2 =
            audioContext.createMediaStreamSource(CharacterAudioStream);

          const merger = audioContext.createChannelMerger(2);

          source.connect(merger, 0, 0);
          source2.connect(merger, 0, 1);

          const destination = audioContext.createMediaStreamDestination();
          merger.connect(destination);

          merger.connect(analyser);

          const bufferLength = analyser.frequencyBinCount;
          const dataArray = new Uint8Array(bufferLength);

          const canvas = canvasRef.current;
          const canvasCtx = canvas.getContext("2d");
          const width = 380;
          const height = 380;
          canvas.width = width;
          canvas.height = height;

          const gradient = canvasCtx.createLinearGradient(
            380,
            0,
            canvas.width,
            canvas.height
          );
          gradient.addColorStop(0, "#D3D3FF");
          gradient.addColorStop(1, "#FFFFFF");

          const logo = new Image();
          logo.src = imageData;
          logo.onload = () => {
            logoRef.current = logo;
          };

          function draw() {
            requestAnimationFrame(draw);

            canvasCtx.fillStyle = gradient;
            canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

            analyser.getByteFrequencyData(dataArray);

            const radius = 55;

            // Set font for the video labels
            canvasCtx.font = `20px ${fontFamily}`;
            canvasCtx.fillStyle = "#ffffff"; // Set text color to white
            canvasCtx.textAlign = "center";

            let firstVideoEndX;
            // First video (centered)
            if (videoStreamsRef.current[0]) {
              const videoX = 36;
              const videoY = 142;
              const videoWidth = 120;
              const videoHeight = 120;

              // Clip the canvas to make circular videos
              // canvasCtx.save();
              // canvasCtx.beginPath();
              // canvasCtx.arc(
              //   videoX + videoWidth / 2,
              //   videoY + videoHeight / 2,
              //   radius,
              //   0,
              //   Math.PI * 2
              // );
              // canvasCtx.closePath();
              // canvasCtx.clip();

              // Set the desired font properties
              canvasCtx.font = `200 16px ${fontFamily}`; // font-weight, font-size, font-family
              canvasCtx.textAlign = "center"; // Center the text horizontally
              canvasCtx.fillStyle = "#000000"; // Set text color to white

              // Add text label below the first video
              canvasCtx.fillText(
                ParticipantName.length > 10
                  ? ParticipantName.substring(0, 10) + "..."
                  : ParticipantName,
                videoX + videoWidth / 2,
                videoY + 0 - 7
              );
              firstVideoEndX = videoX + radius * 2;

              canvasCtx.drawImage(
                videoStreamsRef.current[0],
                videoX,
                videoY,
                videoWidth,
                videoHeight
              );
              canvasCtx.restore();
            }

            // Second video (right above the audio visualizer)
            if (videoStreamsRef.current[1]) {
              const videoX = 224;
              const videoY = 142;
              const videoWidth = 120;
              const videoHeight = 120;
              // canvasCtx.save();
              // canvasCtx.beginPath();
              // canvasCtx.arc(
              //   videoX + videoWidth / 2,
              //   videoY + videoHeight / 2,
              //   radius,
              //   0,
              //   Math.PI * 2
              // );
              // canvasCtx.closePath();
              // canvasCtx.clip();

              // Set the desired font properties
              canvasCtx.font = `200 16px ${fontFamily}`; // font-weight, font-size, font-family
              canvasCtx.textAlign = "center"; // Center the text horizontally
              canvasCtx.fillStyle = "#000000"; // Set text color to white

              // Add text label below the second video
              canvasCtx.fillText(
                CharacterName,
                videoX + videoWidth / 2,
                videoY + 0 - 7
              );

              canvasCtx.drawImage(
                videoStreamsRef.current[1],
                videoX,
                videoY,
                videoWidth,
                videoHeight
              );
              canvasCtx.restore();
            }

            // Audio Visualizer (centered under the videos)
            const visualizerStartY = 205;
            const barWidth = (canvas.width / bufferLength) * 3;
            let barHeight;

            firstVideoEndX += 10.9;
            for (let i = 0; i < 13; i++) {
              barHeight = dataArray[i] / 9;

              canvasCtx.fillStyle = `#9d9dff`;

              // Draw the visualizer (both mirrored top and bottom visualizers close to each other)
              canvasCtx.fillRect(
                firstVideoEndX,
                visualizerStartY,
                barWidth,
                -barHeight
              ); // top visualizer
              canvasCtx.fillRect(
                firstVideoEndX,
                visualizerStartY,
                barWidth,
                barHeight
              ); // mirrored visualizer

              firstVideoEndX += barWidth + 3;
            }

            // Draw logo at the center
            if (logoRef.current) {
              const logoWidth = 188;
              const logoHeight = 52;
              const logoX = (canvas.width - logoWidth) / 2;
              const logoY = 17;
              canvasCtx.drawImage(
                logoRef.current,
                logoX,
                logoY,
                logoWidth,
                logoHeight
              );

              // Draw "charactersdk" text below the logo
              canvasCtx.font = `500 20px ${fontFamily}`;
              canvasCtx.fillStyle = "#000000"; // White text color
              canvasCtx.textAlign = "center";
              canvasCtx.fillText(
                "CharacterSDK",
                canvas.width / 2 + 5,
                logoY + logoHeight + 30
              );

              // Draw a horizontal divider below the text
              canvasCtx.globalAlpha = 0.3;
              canvasCtx.strokeStyle = "#000000"; // White color for divider
              canvasCtx.lineWidth = 2;
              canvasCtx.beginPath();
              canvasCtx.moveTo(115, logoY + logoHeight + 5);
              canvasCtx.lineTo(canvas.width - 110, logoY + logoHeight + 5);
              canvasCtx.stroke();
              canvasCtx.globalAlpha = 1;
            }

            if (messageRef.current) {
              // Set the desired font properties
              canvasCtx.font = `300 16px ${fontFamily}`; // font-weight, font-size, font-family
              canvasCtx.textAlign = "center"; // Center the text horizontally
              canvasCtx.fillStyle = "#000000"; // Set text color to white
              // Add text label below the first video
              canvasCtx.globalAlpha = 0.8;
              // Add text label below the first video
              canvasCtx.fillText(
                messageRef.current.length > 45
                  ? messageRef.current.substring(0, 45) + "..."
                  : messageRef.current,
                canvas.width / 2,
                295
              );
              canvasCtx.globalAlpha = 1;
            }

            // Set the desired font properties
            canvasCtx.font = `300 16px ${fontFamily}`; // font-weight, font-size, font-family
            canvasCtx.textAlign = "center"; // Center the text horizontally
            canvasCtx.fillStyle = "#000000"; // Set text color to white

            // Add text label below the first video
            canvasCtx.fillText("Visit us on", canvas.width / 2, 325);

            // Set the desired font properties
            canvasCtx.font = `400 23px ${fontFamily}`; // font-weight, font-size, font-family
            canvasCtx.textAlign = "center"; // Center the text horizontally
            canvasCtx.fillStyle = "#000000"; // Set text color to white
            // Add text label below the first video
            canvasCtx.fillText("www.videosdk.live", canvas.width / 2, 350);

            // Draw a horizontal divider below the text
            canvasCtx.globalAlpha = 0.3;
            canvasCtx.strokeStyle = "#000000"; // White color for divider
            canvasCtx.lineWidth = 2;
            canvasCtx.beginPath();
            canvasCtx.moveTo(90, 355);
            canvasCtx.lineTo(canvas.width - 90, 355);
            canvasCtx.stroke();
            canvasCtx.globalAlpha = 1;
          }

          draw();

          const ParticipantVideoTracks =
            ParticipantVideoStream.getVideoTracks();
          const videoElements = ParticipantVideoTracks.map((track) => {
            const videoElement = document.createElement("video");
            videoElement.srcObject = new MediaStream([track]);
            videoElement.play();
            return videoElement;
          });
          const CharacterVideoTracks = CharacterVideoStream.getVideoTracks();
          const secondVideoElements = CharacterVideoTracks.map((track) => {
            const videoElement = document.createElement("video");
            videoElement.srcObject = new MediaStream([track]);
            videoElement.play();
            return videoElement;
          });

          videoStreamsRef.current = [...videoElements, ...secondVideoElements];

          const canvasStream = canvas.captureStream(24);

          const combinedStream = new MediaStream();
          canvasStream
            .getVideoTracks()
            .forEach((track) => combinedStream.addTrack(track));
          destination.stream
            .getAudioTracks()
            .forEach((track) => combinedStream.addTrack(track));

          const mediaRecorder = new MediaRecorder(combinedStream, {
            mimeType: `video/${fileType}`,
          });
          mediaRecorder.ondataavailable = (e) => chunksRef.current.push(e.data);

          mediaRecorderRef.current = mediaRecorder;

          mediaRecorder.onstop = async () => {
            const blob = new Blob(chunksRef.current, {
              type: `video/${fileType}`,
            });
            const arrayBuffer = await blob.arrayBuffer();

            const file = new File([blob], `character.${fileType}`, {
              type: `video/${fileType}`,
            });

            try {
              if (file && presignedUrlRef.current) {
                const header = {
                  "Content-Type": "video/webm",
                };
                let preSignedUrl = presignedUrlRef.current
                const uploadResponse = await fetch(preSignedUrl, {
                  method: "PUT",
                  headers: header,
                  body: file,
                });

                if (!uploadResponse.ok) {
                  throw new Error(
                    `Upload failed: ${uploadResponse.statusText}`
                  );
                } 
              }else{
                console.log("Failed to get details of file...")
              }
            } catch (error) {
              console.error("File upload failed", error);
            }

            const url = URL.createObjectURL(blob);

            // Load FFmpeg and convert the file
            // await ffmpeg.load();
            // ffmpeg.writeFile("character.webm", new Uint8Array(arrayBuffer));
            // await ffmpeg.exec(["-i", "character.webm", "output.mp4"]);
            // const data = await ffmpeg.readFile("output.mp4");
            // const mp4Blob = new Blob([data.buffer], { type: "video/mp4" });

            // const file2 = new File([mp4Blob], "character.mp4", {
            //   type: `video/${fileType}`,
            // });
            // const mp4Url = URL.createObjectURL(mp4Blob);

            //setRecordingBlob(file);
            setRecordingBlob(blob);
            setDownloadUrl(url);
          };

          mediaRecorderRef.current = mediaRecorder;

          recordingRef.current = true;
          mediaRecorderRef.current.start();
        } catch (err) {
          console.error("Error accessing media devices.", err);
        }
      }

      setupAudioAndVideo();
    }, []);

    useImperativeHandle(ref, () => ({
      // startRecording() {
      //   recordingRef.current = true;
      //   mediaRecorderRef.current.start();
      // },

      stopRecording() {
        recordingRef.current = false;
        mediaRecorderRef.current.stop();
      },

      onTranscriptionAvailable(message) {
        messageRef.current = message;
      },

      onPresignedUrl(url) {
        presignedUrlRef.current = url;
      },
    }));

    return (
      <div>
        <canvas
          ref={canvasRef}
          style={{
            display: "none",
            margin: "0 auto",
            border: "1px solid black",
          }}
        ></canvas>
      </div>
    );
  }
);

export default Worker;
