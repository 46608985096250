import React from "react";

function LinkedinIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <g clipPath="url(#clip0_265_42)">
        <path
          fill="url(#paint0_linear_265_42)"
          d="M24 0C10.745 0 0 10.745 0 24s10.745 24 24 24 24-10.745 24-24S37.255 0 24 0zm-6.403 35.603h-5.27V18.579h5.27v17.024zm-2.66-19.254c-1.721 0-3.116-1.406-3.116-3.14 0-1.736 1.395-3.142 3.116-3.142 1.72 0 3.116 1.406 3.116 3.141s-1.395 3.141-3.116 3.141zm22.407 19.254H32.1v-8.936c0-2.451-.931-3.82-2.869-3.82-2.11 0-3.211 1.425-3.211 3.82v8.936h-5.054V18.579h5.054v2.293s1.52-2.812 5.13-2.812 6.194 2.204 6.194 6.764v10.779z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_265_42"
          x1="7.029"
          x2="40.971"
          y1="7.029"
          y2="40.971"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2489BE"></stop>
          <stop offset="1" stopColor="#0575B3"></stop>
        </linearGradient>
        <clipPath id="clip0_265_42">
          <path fill="#fff" d="M0 0H48V48H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LinkedinIcon;
