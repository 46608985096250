import React from "react";

function StopSpeakingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <g clipPath="url(#clip0_330_179)">
        <path
          fill="#2B2B2B"
          d="M13.002.57L1 12.572l.998.998 3.507-3.506 2.555 2.8a.703.703 0 001.086-.107.704.704 0 00.119-.392V6.304L14 1.568 13.002.57zM9.265 2.31V1.07A.705.705 0 008.06.57L5.029 3.893H2.911a.708.708 0 00-.706.706v4.236c0 .15.058.282.138.396L9.265 2.31z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_330_179">
          <path fill="#fff" d="M0 0H14V14H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default StopSpeakingIcon;
