import React from "react";

function FacebookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="54"
      fill="none"
      viewBox="0 0 55 54"
    >
      <circle
        cx="27.5"
        cy="27"
        r="23.625"
        fill="url(#paint0_linear_378_28)"
      ></circle>
      <path
        fill="#fff"
        d="M36.298 34.225l1.05-6.668h-6.565v-4.325c0-1.825.915-3.605 3.855-3.605h2.987v-5.676s-2.71-.451-5.298-.451c-5.41 0-8.942 3.194-8.942 8.975v5.082h-6.01v6.668h6.01v16.12c1.207.185 2.441.28 3.699.28s2.492-.095 3.699-.28v-16.12h5.515z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_378_28"
          x1="27.5"
          x2="27.5"
          y1="3.375"
          y2="50.485"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18ACFE"></stop>
          <stop offset="1" stopColor="#0163E0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default FacebookIcon;
