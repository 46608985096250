import React from "react";

function ChatIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="14"
      fill="none"
      viewBox="0 0 20 14"
    >
      <path
        stroke="#86886C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 5H1M19 1H1M19 9H1M15 13H1"
      ></path>
    </svg>
  );
}

export default ChatIcon;
