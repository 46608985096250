import React from "react";

function ExitIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#86886C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 19H3a2 2 0 01-2-2V3a2 2 0 012-2h4M14 15l5-5-5-5M19 10H7"
      ></path>
    </svg>
  );
}

export default ExitIcon;
