import { useMeeting } from "@videosdk.live/react-sdk";
import ExitIcon from "../../icons/ExitIcon";

export function EndCall() {
  const { end, disableWebcam } = useMeeting();

  const hanleClick = () =>{

    end();
  }

  return (
    <div
      className="border-2 border-[#86886C1A] cursor-pointer py-4 lg:py-5"
      onClick={hanleClick}
    >
      <div className=" flex flex-row items-center justify-center text-[#2B2B2B]">
        <ExitIcon />
        <p className="font-mono  text-[#86886C] ml-4 text-xl"> Exit</p>
      </div>
    </div>
  );
}
