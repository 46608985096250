import React, { useState, useEffect, useRef, createRef, useMemo } from "react";
import { usePubSub, useMeeting } from "@videosdk.live/react-sdk";
import { nameTructed, trimSnackBarText } from "../utils/helper";
import WaitingToJoinScreen from "../components/screens/WaitingToJoinScreen";
import useIsMobile from "../hooks/useIsMobile";
import useIsTab from "../hooks/useIsTab";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { useMeetingAppContext } from "../context/MeetingAppContext";
import { meetingLeftReasons } from "../utils/common";
import { TopBar } from "./components/TopBar";
import { CharacterView } from "./components/CharacterView";
import { ParticipantTempView } from "./components/ParticipantTempView";
import { ChatComponent } from "./components/ChatComponent";
import { EndCall } from "./components/EndCall";
import { LatencyView } from "./components/LatencyView";
import useIsBigDesktop from "../hooks/useIsBigScreen";
import ConfirmBox from "../components/ConfirmBox";
import FlowChartView from "./components/FlowchartView";

import LatencyIcon from "../icons/LatencyIcon";
import FlowchartIcon from "../icons/FlowchartIcon";
import ChatIcon from "../icons/ChatIcon";
import ExitIcon from "../icons/ExitIcon";

import PopupComponent from "./components/PopupComponent";
import Worker from "./components/Worker";

const arr = [
  {
    Icon: LatencyIcon,
    Component: LatencyView,
  },
  {
    Icon: FlowchartIcon,
    Component: FlowChartView,
  },
  {
    Icon: ChatIcon,
    Component: ChatComponent,
  },
];

export function MeetingContainer({
  onMeetingLeave,
  setDownloadUrl,
  setRecordingBlob,
  reqId,
  selectedLanguage,
}) {
  const childRef = useRef();
  const localParticipantNameRef = useRef(null);
  const characterParticipantNameRef = useRef(null);
  const [characterJoined, setCharacterJoined] = useState(false);
  const [preSignedUrl, setPreSignedUrl] = useState(null);

  const topBarHeight = 60;
  const { publish } = usePubSub("PRESIGNED", {
    onMessageReceived: ({ payload, senderId }) => {
      if (senderId != localParticipant.id) {
        if (payload.request) {
          publish("message", { persist: true }, { id: reqId, fileType: "webm" }, null);
        } else if (payload.response) {
          setPreSignedUrl(payload.url);
        }
      }
    },
  });

  useEffect(()=>{
    if(childRef.current){
      childRef.current.onPresignedUrl(preSignedUrl)
    }
  },[preSignedUrl])
  useEffect(() => {
    if (childRef.current) {
      childRef.current.startRecording();
    }
  }, [childRef]);
  const { end, localParticipant, meeting } = useMeeting({
    onCharacterJoined: (character) => {
      setCharacterJoined(true);
      characterParticipantNameRef.current = character.displayName;
    },
    onMeetingLeft: () => {
      if (childRef.current) {
        childRef.current.stopRecording();
      }
    },
  });

  const [participantStream, setParticipantStream] = useState({
    audioStream: null,
    videoStream: null,
  });
  const [characterStream, setCharacterStream] = useState({
    audioStream: null,
    videoStream: null,
  });

  const [combinedAudioStream, setCombinedAudioStream] = useState(null);
  const [meetingErrorVisible, setMeetingErrorVisible] = useState(false);
  const [meetingError, setMeetingError] = useState(false);

  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const { participantLeftReason, setParticipantLeftReason } =
    useMeetingAppContext();
  let isBigScreen = useIsBigDesktop();

  const [meetingJoined, setMeetingJoined] = useState(false);

  const mMeetingRef = useRef();
  const containerRef = createRef();
  let containerHeightRef = useRef();
  let containerWidthRef = useRef();

  let isMobile = useIsMobile();
  let isTab = useIsTab();
  let isLGDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1439 });
  let isXLDesktop = useMediaQuery({ minWidth: 1440 });

  useEffect(() => {
    containerHeightRef.current = containerHeight;
    containerWidthRef.current = containerWidth;
  }, [containerHeight, containerWidth]);

  useEffect(() => {
    containerRef.current?.offsetHeight &&
      setContainerHeight(containerRef.current.offsetHeight);
    containerRef.current?.offsetWidth &&
      setContainerWidth(containerRef.current.offsetWidth);

    window.addEventListener("resize", ({ target }) => {
      containerRef.current?.offsetHeight &&
        setContainerHeight(containerRef.current.offsetHeight);
      containerRef.current?.offsetWidth &&
        setContainerWidth(containerRef.current.offsetWidth);
    });
  }, [isBigScreen, isTab, isLGDesktop, isXLDesktop]);

  useEffect(() => {
    // Safely access getTracks only if audioStream is valid
    const audioTracks = [
      ...(participantStream.audioStream
        ? participantStream.audioStream.getTracks()
        : []),
      ...(characterStream.audioStream
        ? characterStream.audioStream.getTracks()
        : []),
    ];

    const combined = new MediaStream(audioTracks);

    setCombinedAudioStream(combined);
  }, [participantStream, characterStream]);

  function onParticipantJoined(participant) {
    // Change quality to low, med or high based on resolution
    participant && participant.setQuality("high");

    localParticipantNameRef.current = participant.displayName;
  }

  async function onMeetingJoined() {
    setMeetingJoined(true);
    const { muteMic, changeMic, changeWebcam, disableWebcam } =
      mMeetingRef.current;
  }
  function onMeetingLeft() {
    onMeetingLeave();
  }

  const _handleOnError = (data) => {
    const { code, message } = data;

    const joiningErrCodes = [
      4001, 4002, 4003, 4004, 4005, 4006, 4007, 4008, 4009, 4010,
    ];

    const isJoiningError = joiningErrCodes.findIndex((c) => c === code) !== -1;
    const isCriticalError = `${code}`.startsWith("500");

    new Audio(
      isCriticalError
        ? `https://static.videosdk.live/prebuilt/notification_critical_err.mp3`
        : `https://static.videosdk.live/prebuilt/notification_err.mp3`
    ).play();

    setMeetingErrorVisible(true);
    setMeetingError({
      code,
      message: isJoiningError ? "Unable to join meeting!" : message,
    });
  };

  // function onParticipantLeft(participant) {
  //   toast(
  //     `${trimSnackBarText(nameTructed(participant.displayName, 15))} ${
  //       participantLeftReason === meetingLeftReasons.TAB_BROWSER_CLOSED
  //         ? "left because of tab closed."
  //         : "left the meeting."
  //     }`,
  //     {
  //       position: "bottom-left",
  //       autoClose: 4000,
  //       hideProgressBar: true,
  //       closeButton: false,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     }
  //   );
  // }

  // function _handleOnMeetingStateChanged(data) {
  //   const { state } = data;

  //   toast(
  //     `${
  //       state === "CONNECTED"
  //         ? "Meeting is connected"
  //         : state === "CONNECTING"
  //         ? "Meeting is connecting"
  //         : state === "FAILED"
  //         ? "Meeting connection failed"
  //         : state === "DISCONNECTED"
  //         ? "Meeting connection disconnected abruptly"
  //         : state === "CLOSING"
  //         ? "Meeting is closing"
  //         : state === "CLOSED"
  //         ? "Meeting connection closed"
  //         : ""
  //     }`,
  //     {
  //       position: "bottom-left",
  //       autoClose: 5000,
  //       type: (state === "FAILED" || state === "DISCONNECTED") && "warning",
  //       hideProgressBar: true,
  //       closeButton: false,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     }
  //   );
  // }

  const mMeeting = useMeeting({
    onParticipantJoined,
    // onParticipantLeft,
    onMeetingJoined,
    onMeetingLeft,
    // onMeetingStateChanged: _handleOnMeetingStateChanged,
    onError: _handleOnError,
  });

  useEffect(() => {
    mMeetingRef.current = mMeeting;
  }, [mMeeting]);

  useEffect(() => {
    window.addEventListener("beforeunload", (event) => {
      setParticipantLeftReason(meetingLeftReasons.TAB_BROWSER_CLOSED);
      event.preventDefault();
      event.returnValue = "";
    });
  }, []);

  let [characterViewHeightRef, setCharacterViewHeightRef] = useState(null);
  let [endCallBtnHeightRef, setEndCallBtnHeightRef] = useState(null);

  let characterViewHeight = useMemo(
    () => characterViewHeightRef?.offsetHeight,
    [characterViewHeightRef, isBigScreen, isTab, isLGDesktop, isXLDesktop]
  );

  let endCallBtnHeight = useMemo(
    () => endCallBtnHeightRef?.offsetHeight,
    [endCallBtnHeightRef, isBigScreen, isTab, isLGDesktop, isXLDesktop]
  );

  let videoHeight = isBigScreen ? 600 : isTab ? 350 : isMobile ? 290 : 350;
  let spacing = isTab ? 48 : 28;

  let ChatMessageHeight = characterViewHeight + topBarHeight + 140 + 12;

  const [modalOpen, setModalOpen] = useState(false);
  const [currentComponent, setCurrentComponent] = useState(null);

  const handleIconClick = (Component) => {
    setCurrentComponent(() => Component);
    setModalOpen(true);
  };

  return (
    <div className="fixed inset-0">
      {combinedAudioStream &&
      characterStream.audioStream &&
      participantStream.audioStream &&
      participantStream.videoStream &&
      characterStream.videoStream ? (
        <Worker
          ParticipantAudioStream={participantStream.audioStream}
          CharacterAudioStream={characterStream.audioStream}
          ParticipantVideoStream={participantStream.videoStream}
          CharacterVideoStream={characterStream.videoStream}
          ParticipantName={localParticipant.displayName}
          CharacterName={"Character"}
          ref={childRef}
          setDownloadUrl={setDownloadUrl}
          setRecordingBlob={setRecordingBlob}
        />
      ) : null}
      <div
        ref={containerRef}
        className="w-full h-full flex flex-col py-4 md:py-0 px-4 overflow-y-auto "
      >
        {meetingJoined ? (
          !isMobile ? (
            <>
              <TopBar
                bottomBarHeight={topBarHeight}
                characterJoined={meetingJoined}
              />
              <div
                style={{ height: containerHeight - (topBarHeight + 48) }}
                className="grid md:grid-cols-12 mt-6 h-full gap-4 "
              >
                <div className="md:col-span-4 h-full ">
                  <div className="flex flex-col">
                    <div
                      ref={setCharacterViewHeightRef}
                      className="flex flex-1"
                    >
                      <CharacterView
                        setCharacterStream={setCharacterStream}
                        selectedLanguage={selectedLanguage}
                      />
                    </div>
                    {characterViewHeight > 0 && (
                      <div className="mt-5 flex flex-1 ">
                        <ChatComponent
                          height={containerHeight - ChatMessageHeight}
                          recorderRef={childRef}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="md:col-span-4 ">
                  <div>
                    <ParticipantTempView
                      setParticipantStream={setParticipantStream}
                    />
                  </div>

                  <div
                    className=" flex flex-col justify-between mt-4 "
                    style={{
                      height:
                        containerHeight -
                        (topBarHeight + videoHeight + 48 + 16),
                    }}
                  >
                    <div className="mt-0 0">
                      <LatencyView
                        height={
                          containerHeight -
                          (topBarHeight +
                            videoHeight +
                            endCallBtnHeight +
                            48 +
                            156 +
                            spacing)
                        }
                      />
                    </div>
                    <div ref={setEndCallBtnHeightRef} className="mt-4">
                      <EndCall />
                    </div>
                  </div>
                </div>
                <div
                  style={{ height: containerHeight - (topBarHeight + 48) }}
                  className="md:col-span-4 "
                >
                  <FlowChartView />
                </div>
              </div>
            </>
          ) : (
            <>
              <TopBar bottomBarHeight={topBarHeight} />
              <div className="flex flex-col  h-full justify-between overflow-y-auto">
                <div className="flex flex-col">
                  <ParticipantTempView
                    setParticipantStream={setParticipantStream}
                  />
                  <CharacterView
                    setCharacterStream={setCharacterStream}
                    selectedLanguage={selectedLanguage}
                  />
                </div>

                <div className="flex flex-row justify-center items-stretch mt-3">
                  {arr.map(({ Icon, Component }, index) => (
                    <div
                      onClick={() => handleIconClick(Component)}
                      className="flex items-center border border-[#86886C33] p-2 rounded mr-4 "
                    >
                      <Icon key={index} />
                    </div>
                  ))}
                  <div
                    onClick={() => end()}
                    className="flex items-center border border-[#86886C33] p-2 rounded"
                  >
                    {" "}
                    <ExitIcon />
                  </div>
                </div>
                {modalOpen && (
                  <PopupComponent
                    open={modalOpen}
                    RenderComponent={currentComponent}
                    onClose={() => setModalOpen(false)}
                    containerHeight={containerHeight}
                  />
                )}
              </div>
            </>
          )
        ) : (
          <WaitingToJoinScreen />
        )}
        <ConfirmBox
          open={meetingErrorVisible}
          successText="OKAY"
          onSuccess={() => {
            setMeetingErrorVisible(false);
          }}
          title={`Error Code: ${meetingError.code}`}
          subTitle={meetingError.message}
        />
      </div>
    </div>
  );
}
