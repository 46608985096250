import {
  createCameraVideoTrack,
  useMeeting,
  useParticipant,
} from "@videosdk.live/react-sdk";
import SwitchCameraIcon from "../../icons/SwitchCamera";

import { useRef, useEffect, useState } from "react";
import useIsBigDesktop from "../../hooks/useIsBigScreen";
import useIsTab from "../../hooks/useIsTab";
import useIsMobile from "../../hooks/useIsMobile";

export function ParticipantTempView({setParticipantStream}) {
  const { localParticipant, changeWebcam } = useMeeting();
  const micRef = useRef(null);
  const isBigScreen = useIsBigDesktop();
  const isTab = useIsTab();
  const isMobile = useIsMobile()

  const [facingMode, setFacingMode] = useState("user");

  const { webcamStream, micStream, webcamOn, micOn, isLocal,enableMic,disableMic } = useParticipant(
    localParticipant.id
  );

  const webcamRef = useRef(null);

  useEffect(() => {
    if (webcamRef.current) {
      if (webcamOn && webcamStream) {
        
        const mediaStream = new MediaStream();
        mediaStream.addTrack(webcamStream.track);
        setParticipantStream(prevState => ({
          ...prevState,
          videoStream: mediaStream 
        }));

        webcamRef.current.srcObject = mediaStream;
        webcamRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        webcamRef.current.srcObject = null;
      }
    }
  }, [webcamStream, webcamOn]);


  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        
        mediaStream.addTrack(micStream.track);
        setParticipantStream(prevState => ({
          ...prevState,
          audioStream: mediaStream 
        }));

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);


  const handleButtonClick = async () => {
    if (facingMode == "user") {
      setFacingMode("environment");
    } else {
      setFacingMode("user");
    }

    let customTrack = await createCameraVideoTrack({
      facingMode: facingMode,
    });
    changeWebcam(customTrack);
  };


  return (
    <div
      style={{ height: isBigScreen ? 600 : isTab ? 350 : isMobile ? 290 : 350 }}
      className=" bg-gradient-to-b from-[#FDCBC880] to-[#F7D0A780]"
    >
      <audio ref={micRef} autoPlay playsInline muted={isLocal} />

      {webcamOn && (
        <div className="relative w-full h-full">
          <button
            onClick={handleButtonClick}
            className="absolute top-2 left-2 bg-white p-1 rounded z-10"
          >
            <SwitchCameraIcon />
          </button>

          <video
            autoPlay
            playsInline
            muted
            ref={webcamRef}
            controls={false}
            className={
              " h-full w-full object-cover flex items-center justify-center flip"
            }
          />
        </div>
      )}
    </div>
  );
}
