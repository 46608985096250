import React from "react";

function WhatsappIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <g clipPath="url(#clip0_265_9)">
        <path
          fill="#4CAF50"
          d="M24.006 0h-.012C10.761 0 0 10.764 0 24a23.82 23.82 0 004.569 14.067l-2.991 8.916 9.225-2.949A23.79 23.79 0 0024.006 48C37.239 48 48 37.233 48 24S37.239 0 24.006 0z"
        ></path>
        <path
          fill="#FAFAFA"
          d="M37.971 33.891c-.579 1.635-2.877 2.991-4.71 3.387-1.254.267-2.892.48-8.406-1.806-7.053-2.922-11.595-10.089-11.949-10.554-.339-.465-2.85-3.795-2.85-7.239s1.749-5.121 2.454-5.841c.579-.591 1.536-.861 2.454-.861.297 0 .564.015.804.027.705.03 1.059.072 1.524 1.185.579 1.395 1.989 4.839 2.157 5.193.171.354.342.834.102 1.299-.225.48-.423.693-.777 1.101-.354.408-.69.72-1.044 1.158-.324.381-.69.789-.282 1.494.408.69 1.818 2.991 3.894 4.839 2.679 2.385 4.851 3.147 5.628 3.471.579.24 1.269.183 1.692-.267.537-.579 1.2-1.539 1.875-2.484.48-.678 1.086-.762 1.722-.522.648.225 4.077 1.92 4.782 2.271.705.354 1.17.522 1.341.819.168.297.168 1.692-.411 3.33z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_265_9">
          <path fill="#fff" d="M0 0H48V48H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default WhatsappIcon;
