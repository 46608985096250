import React from "react";

function XIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="48"
      fill="none"
      viewBox="0 0 49 48"
    >
      <rect width="48" height="48" x="0.5" fill="#000" rx="24"></rect>
      <path
        fill="#fff"
        d="M12.561 12l9.653 13.003L12.5 35.57h2.188l8.502-9.255 6.871 9.255H37.5L27.306 21.84 36.346 12h-2.184l-7.832 8.523L20.004 12H12.56zm3.216 1.62h3.419l15.091 20.328H30.87L15.777 13.62z"
      ></path>
    </svg>
  );
}

export default XIcon;
