import { useMediaQuery } from "react-responsive";

const useIsLGDesktop = () => {
  const isDesktopOrLaptop = useMediaQuery({
    minWidth: 1024,
    maxWidth: 1279,
  });

  return isDesktopOrLaptop;
};

export default useIsLGDesktop;
