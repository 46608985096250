import { Dialog, Popover, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";

const PopupComponent = ({
  open,
  RenderComponent,
  onClose,
  containerHeight,
}) => {
  const bottomBarHeight = 60;
  return (
    <div
      className="flex items-center justify-center"
      //   style={{ height: 600 }}
    >
      {/* <LeaveBTN />

      <MicBTN />
      <WebCamBTN isMobile={isMobile} />
      {(browserName === "Google Chrome or Chromium" ||
        browserName === "Microsoft Edge (Legacy)" ||
        browserName === "Opera") && <OutputMicBTN />}
      <OutlinedButton
        Icon={EllipsisHorizontalIcon}
        onClick={handleClickFAB}
        tooltip={"More Options"}
      /> */}
      <Transition appear show={Boolean(open)} as={Fragment}>
        <Dialog
          as="div"
          className="relative"
          style={{ zIndex: 9999 }}
          onClose={onClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="translate-y-full opacity-0 scale-95"
            enterTo="translate-y-0 opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="translate-y-0 opacity-100 scale-100"
            leaveTo="translate-y-full opacity-0 scale-95"
          >
            <div className="fixed inset-0 overflow-y-hidden">
              <div className="flex h-full items-end justify-end text-center">
                <Dialog.Panel className="w-screen p-4 py-6 transform overflow-hidden bg-light-100 shadow-xl transition-all">
                  <div className=" grid container bg-light-100 ">
                    <RenderComponent containerHeight={containerHeight} onClose={onClose} />
                  </div>
                </Dialog.Panel>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </div>
  );
};

export default PopupComponent;
