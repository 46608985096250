import React from "react";

function StartListeningIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M11.022 3.853a5.687 5.687 0 011.665 4.022 2.188 2.188 0 00-1.312-.438h-.525a.787.787 0 00-.787.788V11.9a.787.787 0 00.787.787h.525a2.188 2.188 0 002.188-2.187V7.875a6.563 6.563 0 00-13.126 0V10.5a2.187 2.187 0 002.188 2.188h.538a.787.787 0 00.775-.788V8.225a.788.788 0 00-.775-.787h-.538c-.476 0-.936.155-1.313.437a5.687 5.687 0 019.71-4.022z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default StartListeningIcon;
