import React, { useState, useEffect, useRef } from "react";
import {
  useCharacter,
  useMeeting,
  useParticipant,
} from "@videosdk.live/react-sdk";
import Lottie from "lottie-react";
import animationData from "../../../src/static/animations/character.json";

import useIsMobile from "../../hooks/useIsMobile";
import useIsTab from "../../hooks/useIsTab";
import { useMediaQuery } from "react-responsive";
import StopSpeakingIcon from "../../icons/StopSpeakingIcon";
import StopListeningIcon from "../../icons/StopListeningIcon";
import StartListeningIcon from "../../icons/StartListeningIcon";
import { MeetingAppContext } from "../../context/MeetingAppContext";

export function CharacterView({ setCharacterStream, selectedLanguage }) {
  const animationDefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  let isMobile = useIsMobile();
  let isTab = useIsTab();
  let isLGDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1439 });
  const { localParticipant } = useMeeting();
  const { disableMic, enableMic } = useParticipant(localParticipant.id);

  const [isCharacterListening, setIsCharacterListening] = useState(true);

  const micRef = useRef(null);
  const joinRef = useRef(null)
  const [characterJoined, setCharacterJoined] = useState(false);
  const { join, micStream, isLocal, characterState, interrupt, micOn } =
    useCharacter(
      {
        id: "general-v1",
        characterMode: "auto_pilot",
        language:
          selectedLanguage == "English"
            ? "en-US"
            : selectedLanguage == "Hindi"
            ? "hi"
            : selectedLanguage == "Spanish"
            ? "si"
            : selectedLanguage == "French"
            ? "fr"
            : null,
      },
      {
        onCharacterJoined: () => {
          setCharacterJoined(true);
        },

        onStreamEnabled: (stream) => {
          if (stream.kind === "video") {
            let vStream = new MediaStream()
            vStream.addTrack(stream.track)
            setCharacterStream((prevState) => ({
              ...prevState,
              videoStream: vStream,
            }));
          }
        },
        // onData: (topic, data) => {
        //   console.log("hello from here", topic, data);
        //   if (topic === "WORKFLOW") {
        //     // const tempId = `graphDiv-${Math.random().toString(36).substr(2, 9)}`;
        //     // setUniqueId(tempId);
        //     // const diagramToSet = convertToStateDiagram(data);
        //     // setDiagram(diagramToSet);
        //     // activeStateRef.current = data.tree.active;
        //     // console.log("diagram", diagram, activeStateRef.current);
        //       // setDiagramData(data)
        //   } else if (topic === "ON_WORKFLOW_STATE_CHANGED") {
        //     // const newDiagram = diagramRef.current;
        //     // let changeDiagram = newDiagram.replace(
        //     //   `class ${activeStateRef.current} active`,
        //     //   `class ${activeStateRef.current} inactive`
        //     // );
        //     // changeDiagram = changeDiagram.replace(
        //     //   `class ${data.active_state} inactive`,
        //     //   `class ${data.active_state} active`
        //     // );
        //     // console.log("changeDiagram", changeDiagram);
        //     // const tempId = `graphDiv-${Math.random().toString(36).substr(2, 9)}`;
        //     // setUniqueId(tempId);
        //     // setDiagram(changeDiagram);
        //     // activeStateRef.current = data.active_state;
        //     // setActiveState(data.active_state)
        //   }
        // },
      }
    );

  useEffect(() => {
    if(joinRef.current != "JOINED"){
      join();
      joinRef.current = "JOINED"
    }
  }, []);

  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);
        console.log({mediaStream}, mediaStream.getAudioTracks())
        setCharacterStream((prevState) => ({
          ...prevState,
          audioStream: mediaStream,
        }));

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);

  const handleClick = () => {
    if (isCharacterListening) {
      disableMic();
    } else {
      enableMic();
    }
    setIsCharacterListening(!isCharacterListening);
  };

  return (
    <div className="border-2 border-[#86886C33] py-3 md:py-3 xl:py-5 bg-gradient-to-b from-[#FDCBC880] to-[#F7D0A780]">
      <audio ref={micRef} autoPlay playsInline muted={isLocal} />

      <div className="flex flex-col items-center justify-center px-2 md:px-12 lg:px-24 xl:px-40 2xl:px-[360px]">
        <Lottie
          loop={animationDefaultOptions.loop}
          autoplay={animationDefaultOptions.autoplay}
          animationData={animationDefaultOptions.animationData}
          rendererSettings={{
            preserveAspectRatio:
              animationDefaultOptions.rendererSettings.preserveAspectRatio,
          }}
          style={{
            height: isMobile ? "44%" : "100%",
            width: isMobile ? "44%" : "100%",
          }}
        />
        <p className=" mt-1 xl:mt-3 text-[#998972] font-mono">
          {characterJoined
            ? !isCharacterListening
              ? "INACTIVE"
              : characterState
              ? `${characterState?.replace("CHARACTER_", "")}...`
              : "CONNECTING..."
            : "JOINING..."}
        </p>{" "}
      </div>
      <div className="flex flex-col xl:flex-row items-center justify-center mt-2 xl:mt-10 ">
        <button
          onClick={handleClick}
          disabled={!characterJoined}
          className="disabled:cursor-auto text-sm xl:text-base flex flex-row items-center justify-center font-mono bg-[#FFFFFF66] py-2 px-2 xl:px-4 text-[#2B2B2B] cursor-pointer mb-2 xl:mb-0 xl:mr-6"
        >
          {isCharacterListening ? (
            <StopListeningIcon />
          ) : (
            <StartListeningIcon />
          )}
          <p className="ml-2">
            {isCharacterListening ? "Stop" : "Start"} Listening
          </p>
        </button>
        <button
          onClick={interrupt}
          disabled={!characterJoined}
          className="disabled:cursor-auto text-sm xl:text-base flex flex-row items-center justify-center font-mono bg-[#FFFFFF66] py-2 px-2 xl:px-4 text-[#2B2B2B] cursor-pointer"
        >
          <StopSpeakingIcon />
          <p className="ml-2">Stop Speaking</p>
        </button>
      </div>
    </div>
  );
}
