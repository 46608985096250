import React from "react";

function StopListeningIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <g clipPath="url(#clip0_330_186)">
        <path
          fill="#000"
          fillRule="evenodd"
          d="M10.781 2.511A6.562 6.562 0 00.437 7.875V10.5a2.187 2.187 0 00.727 1.629l2.773-2.774v-1.13a.788.788 0 00-.774-.787h-.538c-.476 0-.936.155-1.313.437a5.687 5.687 0 018.84-4.734l.63-.63zm.775.64l-.62.62a5.687 5.687 0 011.75 4.105 2.188 2.188 0 00-1.311-.439h-.525a.787.787 0 00-.787.788V11.9a.787.787 0 00.787.787h.525a2.188 2.188 0 002.188-2.187V7.875a6.563 6.563 0 00-2.007-4.724zM3.938 10.77l-1.851 1.85c.175.045.356.068.538.068h.538a.787.787 0 00.775-.788v-1.13z"
          clipRule="evenodd"
        ></path>
        <path stroke="#000" d="M1 14L13 2"></path>
      </g>
      <defs>
        <clipPath id="clip0_330_186">
          <path fill="#fff" d="M0 0H14V14H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default StopListeningIcon;
